import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import logo from 'assets/img/logo.png';
import iconBar from 'assets/icons/icon-bar.svg';
import './style.css';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [btnMenu, setBtnMenu] = useState(false);

  return (
    <div className='navbar-menu'>
      <div className='container'>
        <div className='content-navbar'>
          <Link to='/'>
            <div className='left-navbar'>
              <img src={logo} alt='logo-mochi' />
            </div>
          </Link>
          <div className='btn-bar' onClick={() => setBtnMenu(!btnMenu)}>
            {btnMenu ? <CloseOutlined /> : <img className='iconBar' src={iconBar} alt='btn-bar' />}
          </div>
          <div className={`right-navbar  ${btnMenu ? 'active' : ''}`}>
            {/* <Link to='/papers'>
              <div className='route-papers'>Papers</div>
            </Link> */}
            <Link to='/faq'>
              <div className='route-faq'>FAQs</div>
            </Link>
            <div className='community-channels'>
              <a href='https://twitter.com/MarketMochi' target='_blank' rel='noreferrer'>
                <svg
                  width='25'
                  height='20'
                  viewBox='0 0 25 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.0156 5.125C22.9531 4.42188 23.7969 3.57812 24.4531 2.59375C23.6094 2.96875 22.625 3.25 21.6406 3.34375C22.6719 2.73438 23.4219 1.79688 23.7969 0.625C22.8594 1.1875 21.7812 1.60938 20.7031 1.84375C19.7656 0.859375 18.5 0.296875 17.0938 0.296875C14.375 0.296875 12.1719 2.5 12.1719 5.21875C12.1719 5.59375 12.2188 5.96875 12.3125 6.34375C8.23438 6.10938 4.57812 4.14062 2.14062 1.1875C1.71875 1.89062 1.48438 2.73438 1.48438 3.67188C1.48438 5.35938 2.32812 6.85938 3.6875 7.75C2.89062 7.70312 2.09375 7.51562 1.4375 7.14062V7.1875C1.4375 9.57812 3.125 11.5469 5.375 12.0156C5 12.1094 4.53125 12.2031 4.10938 12.2031C3.78125 12.2031 3.5 12.1562 3.17188 12.1094C3.78125 14.0781 5.60938 15.4844 7.76562 15.5312C6.07812 16.8438 3.96875 17.6406 1.67188 17.6406C1.25 17.6406 0.875 17.5938 0.5 17.5469C2.65625 18.9531 5.23438 19.75 8.04688 19.75C17.0938 19.75 22.0156 12.2969 22.0156 5.78125C22.0156 5.54688 22.0156 5.35938 22.0156 5.125Z'
                    fill='#ffffff'
                  />
                </svg>
              </a>
              <a href='https://t.me/mochi_market' target='_blank' rel='noreferrer'>
                <svg
                  width='22'
                  height='18'
                  viewBox='0 0 22 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M21.9062 1.64062C22.1875 0.328125 21.4375 -0.1875 20.5938 0.140625L1.9375 7.3125C0.671875 7.82812 0.71875 8.53125 1.75 8.85938L6.48438 10.3125L17.5469 3.375C18.0625 3 18.5781 3.23438 18.1562 3.5625L9.20312 11.625L8.875 16.5469C9.39062 16.5469 9.57812 16.3594 9.85938 16.0781L12.1562 13.8281L16.9844 17.3906C17.875 17.9062 18.5312 17.625 18.7656 16.5938L21.9062 1.64062Z'
                    fill='#ffffff'
                  />
                </svg>
              </a>
              <a href='https://mochi-market.medium.com/' target='_blank' rel='noreferrer'>
                <svg
                  width='23'
                  height='18'
                  viewBox='0 0 23 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M2.82812 3.70312V13.7344C2.875 14.0625 2.78125 14.4375 2.54688 14.7188L0.15625 17.5781V17.9531H6.90625V17.5781L4.51562 14.7188C4.23438 14.4375 4.14062 14.0625 4.1875 13.7344V5.0625L10.0938 18H10.7969L15.9062 5.0625V15.3281C15.9062 15.6094 15.9062 15.6562 15.7188 15.8438L13.8906 17.625V18H22.7969V17.625L21.0156 15.8906C20.875 15.7969 20.7812 15.5625 20.8281 15.375V2.625C20.7812 2.4375 20.875 2.25 21.0156 2.15625L22.7969 0.421875V0H16.5625L12.0625 11.1562L7 0H0.4375V0.421875L2.54688 2.95312C2.73438 3.14062 2.875 3.42188 2.82812 3.70312Z'
                    fill='#ffffff'
                  />
                </svg>
              </a>
              {/* <a href='https://discord.com/invite/XCrgFKCEjT' target='_blank' rel='noreferrer'>
                <svg
                  width='21'
                  height='24'
                  viewBox='0 0 21 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13.9219 11.4375C13.9219 10.6875 13.3594 10.0781 12.7031 10.0781C12 10.0781 11.4375 10.6875 11.4375 11.4375C11.4375 12.1406 12 12.75 12.7031 12.75C13.3594 12.75 13.9219 12.1406 13.9219 11.4375ZM8.29688 10.0781C7.64062 10.0781 7.07812 10.6875 7.07812 11.4375C7.07812 12.1406 7.64062 12.75 8.29688 12.75C9 12.75 9.51562 12.1406 9.51562 11.4375C9.5625 10.6875 9 10.0781 8.29688 10.0781ZM21 2.48438C21 1.125 19.875 0 18.5156 0H2.4375C1.07812 0 0 1.125 0 2.48438V18.7031C0 20.1094 1.07812 21.1875 2.4375 21.1875H16.0312L15.4219 18.9844C18.9375 22.2188 17.9531 21.375 21 24V2.48438ZM17.5781 13.875C17.5781 13.875 16.5469 15.6094 13.875 15.7031C13.875 15.7031 13.4531 15.1875 13.0781 14.7188C14.6719 14.2969 15.2812 13.3125 15.2812 13.3125C14.7656 13.6406 14.2969 13.8281 13.875 14.0156C11.2969 15.0938 8.4375 14.7188 6.375 13.5938C6.375 13.5469 6.09375 13.4062 5.90625 13.2656C5.90625 13.2656 6.46875 14.25 8.01562 14.7188C7.64062 15.1406 7.21875 15.7031 7.21875 15.7031C4.54688 15.6094 3.5625 13.875 3.5625 13.875C3.5625 9.98438 5.25 6.89062 5.25 6.89062C6.98438 5.57812 8.625 5.625 8.625 5.625L8.76562 5.76562C6.60938 6.375 5.625 7.3125 5.625 7.3125C5.625 7.3125 5.85938 7.17188 6.32812 6.98438C9.09375 5.76562 12.4219 5.76562 15.2812 7.3125C15.2812 7.3125 14.3438 6.42188 12.2812 5.8125L12.4688 5.625C12.4688 5.625 14.1094 5.57812 15.8438 6.89062C15.8438 6.89062 17.5781 9.98438 17.5781 13.875Z'
                    fill='#ffffff'
                  />
                </svg>
              </a> */}
            </div>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://app.mochi.market'
              className='btn-enter-app'
            >
              <button type='button'>Enter App</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
